/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { TextField } from '@material-ui/core';

import {
  BooleanInput,
  CheckboxGroupInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useInput,
  useQuery,
  useResourceContext,
} from 'react-admin';
import { FormSpy, useForm } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanerLevels, parseDate } from '../../../helpers';
import {
  checkClassification,
  checkMinHourlyRate,
  isInterval,
} from '../validation';
import { durationToSeconds } from './Form/utils';
import ContractWorkTimeInput from './Form/ContractWorkTimeInput';
import ContractTypeSelect from './Form/ContractTypeSelect';
import ContractMetadataForm from './Form/ContractMetadataForm';
import { defaultMetadataFields, getSubtype } from './Form/state';
import { shouldDisplayInput } from './Form/checks';
import { ReferencePlanning } from './Form/ReferencePlanning';

const levelChoices = Object.keys(cleanerLevels).map(level => {
  return {
    id: level,
    name: level,
  };
});

export const getDefaultValues = location => {
  if (!location) {
    return {};
  }

  const values = queryString.parse(location.search.slice(1));
  values.full_time = values.full_time === 'true';

  return values;
};

const sectionStyle = {
  width: '100%',
  display: 'flex',
  gap: 30,
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'flex-start',
};

const Form = ({ aside, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const initialValues = getDefaultValues(location);

  const resource = useResourceContext();
  const cleanerId = initialValues?.cleaner_id || props.record.cleaner_id;

  const { data: cleaner, loading, error } = useQuery({
    type: 'getOne',
    resource: 'cleaner',
    payload: { id: cleanerId },
  });

  const { data: contracts } = useQuery({
    type: 'getList',
    resource,
    payload: {
      filter: { cleaner_id: cleanerId },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'start_date', order: 'DESC' },
    },
  });

  const currentContract = props?.record;

  return (
    <SimpleForm
      {...props}
      initialValues={initialValues}
      redirect={() => {
        history.goBack();
      }}
    >
      <h2
        style={{
          fontWeight: 600,
          padding: '2rem 0',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {cleaner?.fullname}
      </h2>
      <section style={sectionStyle}>
        <FormDataConsumer>
          {({ formData }) => {
            const form = useForm();
            return (
              <SelectInput
                style={{ width: 230 }}
                label={'Nature du contrat'}
                source="contract_type"
                choices={[
                  { id: 'CDI', name: 'CDI' },
                  { id: 'CDD', name: 'CDD' },
                ]}
                defaultValue="CDI"
                validate={[required()]}
              />
            );
          }}
        </FormDataConsumer>
        <ContractTypeSelect
          source="metadata"
          resource={resource}
          cleanerId={cleanerId}
        />
      </section>
      <section style={sectionStyle}>
        <FormDataConsumer>
          {({ formData }) => {
            const form = useForm();
            if (
              formData.contract_type === 'CDD' &&
              formData.metadata?.temporaryContractJustification &&
              formData.contract_subtype !==
                formData.metadata?.temporaryContractJustification
            ) {
              form.change(
                'contract_subtype',
                formData.metadata?.temporaryContractJustification,
              );
            }

            return (
              shouldDisplayInput(
                formData.contract_type,
                formData.metadata?.type,
                'temporaryContractJustification',
              ) && (
                <>
                  <SelectInput
                    style={{ width: 350 }}
                    source="metadata.temporaryContractJustification"
                    label="Motif du contrat temporaire"
                    choices={[
                      {
                        id: 'remplacement',
                        name: 'Remplacement',
                      },
                      {
                        id: 'accroissement',
                        name: "Accroissement d'activité temporaire",
                      },
                    ]}
                    validate={[required()]}
                  />
                  {formData.metadata?.temporaryContractJustification ===
                    'remplacement' && (
                    <TextInput
                      style={{ width: 350 }}
                      label={'Nom du remplaçant'}
                      source={'metadata.replacedEmployeeName'}
                      validate={[required()]}
                    />
                  )}
                </>
              )
            );
          }}
        </FormDataConsumer>
      </section>
      <section style={sectionStyle}>
        <DateInput
          source="start_date"
          validate={[required()]}
          parse={parseDate}
        />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              shouldDisplayInput(
                formData.contract_type,
                formData.metadata?.type,
                'end_date',
              ) && (
                <DateInput
                  source="end_date"
                  validate={[required()]}
                  parse={parseDate}
                />
              )
            );
          }}
        </FormDataConsumer>
      </section>
      <section
        style={{
          ...sectionStyle,
          alignItems: 'baseline',
        }}
      >
        <SelectInput
          source="level"
          defaultValue="ASP"
          choices={levelChoices}
          validate={[required()]}
        />
        <SelectInput
          source="classification"
          emptyValue={null}
          allowEmpty
          defaultValue="A"
          choices={[
            {
              id: 'A',
              name: 'A',
            },
            {
              id: 'B',
              name: 'B',
            },
          ]}
          validate={[checkClassification]}
        />
        <NumberInput
          label="Taux horaire"
          source="hourly_rate"
          defaultValue={12.13}
          step={0.01}
          validate={[required(), checkMinHourlyRate]}
        />
        <BooleanInput
          disabled={true}
          label="Temps plein"
          source="full_time"
          defaultValue={false}
        />
      </section>

      <FormDataConsumer>
        {({ formData }) => {
          return (
            <TextField
              type={'hidden'}
              // type={'text'}
              disabled={true}
              value={formData?.contract_subtype}
            />
          );
        }}
      </FormDataConsumer>
      <section style={{ ...sectionStyle, marginBottom: 25, marginTop: -15 }}>
        <FormDataConsumer>
          {({ formData }) => {
            return <ContractWorkTimeInput type={formData.metadata?.type} />;
          }}
        </FormDataConsumer>
      </section>
      <section style={sectionStyle}>
        <FormDataConsumer>
          {({ formData }) =>
            durationToSeconds(formData.contract_worked_time) <=
              16 * 60 * 60 && (
              <SelectInput
                style={{ width: 300 }}
                source="comment"
                label="Raison si moins 16h"
                choices={[
                  {
                    id: 'Contraintes Personnelles',
                    name: 'Contraintes Personnelles',
                  },
                  { id: 'Plusieurs Activités', name: 'Plusieurs Activités' },
                  {
                    id: 'Poursuivre ses études',
                    name: 'Poursuivre ses études',
                  },
                ]}
              />
            )
          }
        </FormDataConsumer>
      </section>
      <section style={{ ...sectionStyle, width: '60%' }}>
        <TextInput
          fullWidth={true}
          multiline
          source="comment"
          resettable
          label={'Commentaire'}
        />
      </section>

      <ContractMetadataForm source="metadata" record={currentContract} />
      {currentContract?.id && currentContract?.metadata?.type && (
        <ReferencePlanning
          cleanerId={cleanerId}
          contractId={currentContract?.id}
          startDate={currentContract?.start_date}
          type={currentContract?.metadata?.type}
          endDate={currentContract?.end_date}
        />
      )}
      <FormSpy subscription={{ valid: true, values: true }}>
        {({ values }) => {
          const cleanerId = values.cleaner_id ? values.cleaner_id : null;

          return React.cloneElement(aside, { cleanerId });
        }}
      </FormSpy>
    </SimpleForm>
  );
};

Form.propTypes = {
  aside: PropTypes.element.isRequired,
};

export default Form;
