import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { translate as translateComponent } from 'react-admin';
import { useQuery } from 'react-admin';
import { getUser } from '../../../../user';

const AppointmentConfirmationButton = ({ className, record, location }) => {
  const { data: affectations } = useQuery({
    type: 'getManyReference',
    resource: 'affectation',
    payload: {
      target: 'cleaner_id',
      id: record.id,
      sort: { field: 'id', order: 'DESC' },
      pagination: { page: 1, perPage: 10 },
      filter: { type: 'Permanent', active: true },
    },
  });

  const formUrl = 'https://forms.cleany.fr/confirmation-rendez-vous';

  const removeDuplicatedIdFromArray = (array) => {
    return array.filter((firstObject, i, arr) => {
      return (
        arr.findIndex((secondObject) => {
          return secondObject.account_id === firstObject.account_id;
        }) === i
      );
    });
  };

  if (!affectations)
    return (
      <MenuItem>
        <ListItemText primary="loading" />
      </MenuItem>
    );

  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          const appointmentLocation = location === 'site' ? 'site' : 'bureau';
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${formUrl}?location=${appointmentLocation}` +
                    `&agent_silae_id=${record.silae_id}` +
                    `&agent_id=${record.id}` +
                    `&agent_email=${record.email}` +
                    `&agent_surname=${record.firstname}` +
                    `&agent_name=${record.lastname}` +
                    `&hr_fullname=${user.fullname}` +
                    `${removeDuplicatedIdFromArray(affectations)
                      .map((affectation, index) => {
                        const indexPlusOne = index + 1;
                        return (
                          `&agent_site_${indexPlusOne}=${affectation.site_address}` +
                          `&agent_site_name_${indexPlusOne}=${affectation.account_name}`
                        );
                      })
                      .join('')}`,
                );
              }}
            >
              <ListItemText
                primary={`Confirmation de rdv ${
                  appointmentLocation === 'site' ? 'sur' : 'au'
                } ${appointmentLocation}`}
              />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

AppointmentConfirmationButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  location: PropTypes.string,
};

AppointmentConfirmationButton.defaultProps = {
  className: undefined,
  record: undefined,
  location: PropTypes.string,
};

export default translateComponent(AppointmentConfirmationButton);
